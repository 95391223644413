import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import { useStyles } from "./styles";
import SocialIcon from "../SocialIcon";

const SecondaryFooter = ({ classes, secondaryFooterData }) => {
  return (
    <Grid container className={classes.secondaryFooter}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <p className={classes.copyrightText}>
          Copyright © {new Date().getFullYear()} SingularityNET All rights reserved.
        </p>
        <p className={classes.copyrightText}>
          Stichting SingularityNET Barbara Strozzilaan 362 1083 HN Amsterdam The Netherlands
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <ul className={classes.socialIconsList}>
          {secondaryFooterData.map((socailMedia) => (
            <SocialIcon key={socailMedia.title} socailMedia={socailMedia} />
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default withStyles(useStyles)(SecondaryFooter);
