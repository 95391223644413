import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Logo from "../../assets/images/BlackLogo.svg";
import { useStyles } from "./styles";

const FooterLink = ({ classes, label, link, image, externalLink }) => {
  return (
    <li className={classes.footerLinks}>
      <a
        href={link}
        className={classes.footerLinkText}
        title={label}
        target={externalLink ? "_blank" : "_self"}
        rel="noopener noreferrer"
      >
        {image ? <img src={Logo} alt="SingularityNET" loading="lazy" /> : label}
      </a>
    </li>
  );
};

export default withStyles(useStyles)(FooterLink);
