import React from "react";
import GitHubIcon from "@material-ui/icons/GitHub";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";

const LinksBelowLogo = [
  { label: "Contact  Us", link: "https://singularitynet.io/contact/" },
  { label: "Jobs", link: "https://singularitynet.io/jobs/" },
];

const PrimaryFooterMain = [
  {
    title: "Products",
    children: [
      {
        label: "AI Marketplace",
        link: "https://beta.singularitynet.io/",
        external: true,
      },
      {
        label: "AGI Staking",
        link: "https://staking.singularitynet.io/",
        external: true,
      },
      {
        label: "AI Publisher",
        link: "https://publisher.singularitynet.io/",
        external: true,
      },
      {
        label: "Request For AI (RFAI)",
        link: "https://rfai.singularitynet.io/",
        external: true,
      },
      {
        label: "Developer Portal",
        link: "https://dev.singularitynet.io/",
        external: true,
      },
    ],
  },
  {
    title: "Research",
    children: [
      {
        label: "Research Initiatives",
        link: "https://singularitynet.io/research/",
      },
      {
        label: "White Paper",
        link: "https://public.singularitynet.io/whitepaper.pdf",
        external: true,
      },
    ],
  },
  {
    title: "Community",
    children: [
      {
        label: "Official Blog",
        link: "https://blog.singularitynet.io/",
        external: true,
      },
      {
        label: "Forum",
        link: "https://community.singularitynet.io/",
        external: true,
      },
      {
        label: "Telegram",
        link: "https://telegram.me/singularitynet",
        external: true,
      },
      { label: "Podcast", link: "https://singularitynet.io/podcast/" },
    ],
  },
  {
    title: "About",
    children: [
      {
        label: "SingularityNET Foundation",
        link: "https://singularitynet.io/aboutus/",
      },
      {
        label: "Singularity Studio",
        link: "https://singularity.studio/",
        external: true,
      },
      { label: "Team", link: "https://singularitynet.io/aboutus/" },
      { label: "Newsroom", link: "https://singularitynet.io/newsroom/" },
      { label: "Ecosystem", link: "https://singularitynet.io/ecosystem/" },
      { label: "Roadmap", link: "https://singularitynet.io/roadmap/" },
    ],
  },
];

const SecondaryFooter = [
  { title: "Github", icon: <GitHubIcon />, link: "https://github.com/singnet" },
  {
    title: "Twitter",
    icon: <TwitterIcon />,
    link: "https://twitter.com/singularity_net",
  },
  {
    title: "Facebook",
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/singularityNET.io",
  },
  {
    title: "Linkedin",
    icon: <LinkedInIcon />,
    link: "https://www.linkedin.com/company/singularitynet/",
  },
  {
    title: "Youtube",
    icon: <YouTubeIcon />,
    link: "https://www.youtube.com/channel/UCbTE8vfz5zuEK5uRnc2yjrw",
  },
  {
    title: "Instagram",
    icon: <InstagramIcon />,
    link: "https://instagram.com/singularitynet.io",
  },
];

export const FooterData = {
  LinksBelowLogo,
  PrimaryFooterMain,
  SecondaryFooter,
};
