import { createMuiTheme } from "@material-ui/core/styles";

export const MUITheme = createMuiTheme({
  palette: {
    primary: {
      main: "#4086ff",
    },
    text: {
      primary: "#212121",
      secondary: "#ccc",
      purple: "#220D3A",
      white: "#fff",
      lightShadedGray: "#9b9b9b",
      darkShadedGrey: "#666",
      blue: "#4086ff",
      errorBg: "#FDE5E8",
      greyBg: "#FAFAFA",
    },
    background: {
      disabled: { gray: "#D6D6D6" },
      hover: {
        blue: "#ecf3fe",
        red: "#D0021B",
        black: "#333",
      },
      grey: "#D6D6D6",
      mainContent: "#fafafa",
      footer: "#211D24",
      white: "#fff",
      black: "#333",
      red: "#D0021B",
      succesBox: "#E7FFF8",
      alertBox: "#FDE5E8",
      warningBox: "#FDF3E5",
      infoBox: "#DEEAFF",
    },
    border: {
      primary: "#f5f7f8",
      secondary: "#ccc",
      mainContent: "#fafafa",
      alertBox: "#E67381",
      warningBox: "#F18D5A",
      grey: "#D6D6D6",
      inputBorder: "rgba(25,25,25,.32)",
    },
    success: { main: "#00C48C" },
    error: { main: "#B00020" },
    infoBoxLink: "#067AD7",
    warning: { main: "#F18D5A" },
  },
  typography: {
    primary: {
      main: "Muli, sans-serif",
    },
  },
});

export default MUITheme;
