import React from "react";
import { withStyles } from "@material-ui/core/styles";

import PrimaryFooter from "./PrimaryFooter";
import SecondaryFooter from "./SecondaryFooter";
import { useStyles } from "./styles";
import { FooterData } from "../../utility/constants/footer";

const Footer = ({ classes }) => {
  return (
    <footer className={classes.footer}>
      <div className={classes.footerWrapper}>
        <PrimaryFooter linksBelowLogo={FooterData.LinksBelowLogo} primaryFooterData={FooterData.PrimaryFooterMain} />
        <SecondaryFooter secondaryFooterData={FooterData.SecondaryFooter} />
      </div>
    </footer>
  );
};

export default withStyles(useStyles)(Footer);
