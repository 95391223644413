import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import { localStorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import MobileHeader from "./MobileHeader";
import NavBar from "./NavBar";
import { navData, userActions } from "../../utility/constants/header";
import SnetLogo from "../../assets/images/logo.png";
import UpdateNotification from "../UpdateNotification";
import { useStyles } from "./styles";

const Header = ({ classes, fixHeader }) => {
  const [showUpdateNotification, setShowUpdateNotificationBar] = useLocalStorage(
    localStorageKeys.SHOW_PHASE2_NOTIFICATION,
    true
  );

  const onUpdateCloseClick = () => {
    setShowUpdateNotificationBar(false);
  };

  return (
    <div className={`${classes.header} ${classes.addBgColor}`}>
      <div className={classes.updateNotificationBar}>
        <UpdateNotification showNotification={showUpdateNotification} onCloseClick={onUpdateCloseClick} />
      </div>
      <div className={classes.wrapper}>
        <Grid container>
          <Grid item md={3} className={classes.logoSection}>
            <MobileHeader navigationData={navData} userActions={userActions} />
            <h1>
              <a href="#" className={classes.logoAnchor}>
                <img src={SnetLogo} alt="SingularityNET" />
              </a>
            </h1>
          </Grid>
          <Grid item md={6} className={classes.navigationSection}>
            <NavBar navigationData={navData} />
          </Grid>
          <Grid item md={3} className={classes.userActionSection}>
            <ul>
              <li>
                <a href={userActions[0].url} title={userActions[0].name} target="_blank" rel="noreferrer">
                  {userActions[0].name}
                </a>
              </li>
              <li>
                <a
                  href={userActions[1].url}
                  title={userActions[1].name}
                  target="_blank"
                  className={classes.signUpLink}
                  rel="noreferrer"
                >
                  {userActions[1].name}
                  <span> {userActions[1].name_responsive}</span>
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(useStyles)(Header);
