import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { useStyles } from "./styles";
import FooterLink from "../../FooterLink";
import FooterLinkTitle from "../../FooterLinkTitle";

const FooterLinks = ({ classes, footerLinks }) => {
  return (
    <div className={classes.footerRightSideLinks}>
      {footerLinks.map((footerLink) => (
        <ul key={footerLink.title} className={classes.footerLinksList}>
          <FooterLinkTitle title={footerLink.title} />
          {footerLink.children.map((subLink) => (
            <FooterLink key={subLink.label} link={subLink.link} label={subLink.label} externalLink={subLink.external} />
          ))}
        </ul>
      ))}
    </div>
  );
};

export default withStyles(useStyles)(FooterLinks);
