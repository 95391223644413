import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FooterLinks from "./FooterLinks";
import FooterLink from "../FooterLink";
import { useStyles } from "./styles";
import FooterLogo from "./FooterLogo";

const PrimaryFooter = ({ classes, primaryFooterData, linksBelowLogo }) => {
  return (
    <div className={classes.PrimaryFooter}>
      <div className={classes.LeftData}>
        <FooterLogo />
        <ul className={classes.footerLogoSection}>
          {linksBelowLogo.map((item) => (
            <FooterLink key={item.label} link={item.link} label={item.label} externalLink={item.external} />
          ))}
        </ul>
      </div>
      <FooterLinks footerLinks={primaryFooterData} />
    </div>
  );
};

export default withStyles(useStyles)(PrimaryFooter);
