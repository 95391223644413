import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "./assets/images/bg.svg";


export const useStyles = makeStyles((theme) => ({
  headerBannnerContainer: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  iframeWrapper: {
      paddingTop:116,
      marginBottom:20
  },
  footer: {
      marginTop:0
  }
}));
