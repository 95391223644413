import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useStyles } from "./styles";
import Iframe from "react-iframe";
import React, { useEffect } from "react";
import useWindowSize from "./hooks/useWindowSize";

function App() {
  const classes = useStyles();

  const iframeLoaded = (e) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="App">
      <div className={classes.headerBannnerContainer}>
        <Header />
        <div className={classes.iframeWrapper}>
          <Iframe
            url="https://docs.google.com/forms/d/e/1FAIpQLScTaWRsIdcocz4vDWuuOBsZ2Hnx25Y85UcpuyRbUgnxmzJ3pg/viewform?embedded=true"
            width="800px"
            height="1250px"
            id="agix-google-form"
            display="initial"
            position="relative"
            frameBorder={0}
            scrolling="on"
            title="Singularitynet AGIX Hardfork Form"
            allowFullScreen
            onLoad={iframeLoaded}
          />
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
