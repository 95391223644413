import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { useStyles } from "./styles";

const SocialIcon = ({ classes, socailMedia }) => {
  return (
    <li className={classes.socialIconsLink}>
      <a
        href={socailMedia.link}
        title={socailMedia.title}
        className={classes.socialIcon}
        target="_blank"
        rel="noopener noreferrer"
      >
        {socailMedia.icon}
      </a>
    </li>
  );
};

export default withStyles(useStyles)(SocialIcon);
